import React, { useState } from "react"
import { connect } from "react-redux"
import "./statuspage.scss"

const StatusPage = ({
  web3, walletAddress, connected
}) => {
  const [show, setShow] = useState(false)

  const checkStatusHandler = () => {
    alert('click')
  }

  return (
    <div className="status-container font-staatliches mx-auto">
      <div className="content pb-10 px-2">
        <h2 className="uppercase text-5xl lg:text-6xl pt-32 lg:pt-36 pb-6 lg:pb-8 text-center font-bold">
          Check your warrior status!
        </h2>
        <div className="search-box flex flex-col justify-center items-center w-full sm:w-98 px-10 pt-10 pb-4 mb-10 mt-6 rounded-3xl mx-auto">
          <p className="text-center text-xl text-gray-700">
            Lorem ipsum dolor sit amet, consectetur labore et dolore magna aliqua. or in reprehenderit in voluptate velit esse cillum dolore
          </p>
          <input type="text" className="w-full sm:w-3/4 h-16 mb-8 mt-6 px-6 rounded-full text-2xl text-center" placeholder="TOKEN ID" />
          <button className="text-white px-20 py-2 text-3xl sm:text-4xl rounded-full btn-check" onClick={checkStatusHandler}>
            Check
          </button>
          <div role="button" tabIndex={0} className="flex items-center mt-10 w-full cursor-pointer" onClick={() => setShow(!show)} onKeyDown={() => setShow(!show)}>
            <img src="/icon_arrow_right.png" alt="icon arrow" className={`mr-2 opacity-60 w-4 ${show ? "transform rotate-90" : ""}`} />
            <p className="text-gray-500 text-xl uppercase">
              How to find my token Id?
            </p>
          </div>
          {show && (
            <div className="w-full mt-2 pl-5">
              <p className="text-md text-gray-500">
                Lorem ipsum dolor sit amet, consectetur labore et dolore magna aliqua. or in reprehenderit in voluptate velit esse cillum dolore
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const stateProps = (state) => ({
  web3: state.web3,
  walletAddress: state.walletAddress,
  connected: state.connected
});

export default connect(stateProps, null)(StatusPage);