import React from 'react'
import Layout from '../components/layout/layout'
import StatusPage from '../components/partials/StatusPage'

const Status = () =>{
  return(
    <Layout page="status">
      <StatusPage />
    </Layout>
  )
}

export default Status